import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import './QRCodeGeneratorWithImage.css';

const QRCodeGeneratorWithImage = () => {
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [value3, setValue3] = useState('');
  const [imageBase64, setImageBase64] = useState('');

  const handleChangeValue1 = (event) => setValue1(event.target.value);
  const handleChangeValue2 = (event) => setValue2(event.target.value);
  const handleChangeValue3 = (event) => setValue3(event.target.value);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const maxDimension = 100; // Adjust this value as needed
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxDimension) {
              height *= maxDimension / width;
              width = maxDimension;
            }
          } else {
            if (height > maxDimension) {
              width *= maxDimension / height;
              height = maxDimension;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          const resizedBase64 = canvas.toDataURL('image/png');
          setImageBase64(resizedBase64);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const qrData = JSON.stringify({ value1, value2, value3, image: imageBase64 });

  return (
    <div className="qr-code-container">
      <div className="qr-code-form">
        <h2>Generate QR Code with Image</h2>
        <input
          type="text"
          placeholder="Enter value 1"
          value={value1}
          onChange={handleChangeValue1}
        />
        <input
          type="text"
          placeholder="Enter value 2"
          value={value2}
          onChange={handleChangeValue2}
        />
        <input
          type="text"
          placeholder="Enter value 3"
          value={value3}
          onChange={handleChangeValue3}
        />
        <input type="file" accept="image/*" onChange={handleImageUpload} />
      </div>
      <div className="qr-code">
        <QRCode
          value={qrData}
          size={256}
          renderAs="canvas"
          imageSettings={{
            src: 'https://example.com/your-image.png', // Replace with your own image URL
            x: null, // default center
            y: null, // default center
            height: 48,
            width: 48,
            excavate: true, // make the background transparent behind the logo
          }}
        />
      </div>
    </div>
  );
};

export default QRCodeGeneratorWithImage;
