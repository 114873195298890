import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Navigation } from "./components/navigation";
import { NoticeBoardNavigation } from "./components/navigationNoticeBoard";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Gallery } from "./components/gallery";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import { NoticeBoard } from "./components/NoticeBoard";
import Login from "./components/Login";
import PhotoUpload from "./components/PhotoUpload";
import QRCodeGenerator from "./components/QRCodeGenerator";
import WebcamCapture from "./components/WebcamCapture";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
// In your App.js or main component


import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <Routes>
      <Route
            path="/"
            element={
              <>
                <Navigation />
                <Header data={landingPageData.SlideShow} />
                <About data={landingPageData.About} />
                <Team data={landingPageData.Team} />
                <Gallery data={landingPageData.Gallery} />
                <Contact data={landingPageData.Contact} />
              </>
            }
          />
        <Route path="/login" element={
          <>
            <NoticeBoardNavigation />
            <Login />
          </>
        }/>
        <Route path="/qrcode" element={
          <>
            <NoticeBoardNavigation />
            <QRCodeGenerator />
          </>
        }/>
        <Route path="/wqrcode" element={
          <>
            <NoticeBoardNavigation />
            <WebcamCapture />
          </>
        }/>
        <Route path="/noticeboard" element={
          <>
            <NoticeBoardNavigation />
            <NoticeBoard data={landingPageData.Notices}/>
          </>
        }/>
        <Route path="/photoupload" element={
          <>
            <PhotoUpload />
          </>
        }/>
        <Route
            path="*"
            element={
              <>
                <Navigation />
                <Header data={landingPageData.SlideShow} />
                <About data={landingPageData.About} />
                <Team data={landingPageData.Team} />
                <Gallery data={landingPageData.Gallery} />
                <Contact data={landingPageData.Contact} />
              </>
            }
          />
      </Routes>
    </Router>
  );
};

export default App;
