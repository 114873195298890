// src/components/PhotoUpload.js
import React, { useState } from "react";
import { storage } from "../config/firebaseConfig";
import { ref, uploadBytes } from "firebase/storage";

function PhotoUpload() {
  const [file, setFile] = useState(null);
  const [person, setPerson] = useState("");

  // List of people to choose from
  const people = [
    "Prof Dhruv Sen Singh",
    "Ravi Ranjan Singh",
    "Saurabh Singh",
    "Aarti Singh",
    "Mayank Singh",
    "Shubham Sharma",
    "Neelam Singh",
    "Azra Arshad",
    "Pankaj Singh",
    "Arun Sinha",
  ]; // Replace with your actual list

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle person selection from dropdown
  const handlePersonChange = (e) => {
    setPerson(e.target.value);
  };

  // Handle the file upload
  const handleUpload = () => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    if (!person) {
      alert("Please select a person from the dropdown.");
      return;
    }

    // Get the file extension
    const extension = file.name.split(".").pop();

    // Create a new filename using the person's name
    const fileName = `${person.replace(/\s+/g, "_")}.jpg`;

    // Create a reference to the storage location
    const storageRef = ref(storage, `teams/${fileName.toLowerCase()}`);

    // Upload the file
    uploadBytes(storageRef, file)
      .then(() => {
        alert("File uploaded successfully!");
      })
      .catch((error) => {
        console.error("Upload failed:", error);
      });
  };

  return (
    <div>
      <div id="photo-upload">
        <div className="container"></div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-5">
            <h2>Photo Upload</h2>
            <div className="form-group">
              <label>
                Select Person:
                <select value={person} onChange={handlePersonChange}>
                  <option value="">--Select a person--</option>
                  {people.map((p) => (
                    <option key={p} value={p}>
                      {p}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-5">
            <div className="form-group">
              <label>
                Choose a file:
                <input type="file" onChange={handleFileChange} />
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-5">
            <div className="form-group">
              <button onClick={handleUpload}>Upload</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoUpload;
