import React from "react";
import { Link } from "react-router-dom";


export const NoticeBoardNavigation = () => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
        <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="/">
            <img src="logo0.png" alt="Rustle Court" className="navbar-logo" />
            Rustle Court
          </a>{" "}
        </div>
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/">Home</a>
            </li>
            {/* <li>
              <Link to="http://localhost:4100/">Login</Link>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};
