import React from "react";
import Slideshow from "../components/Slideshow"

export const Header = props => {
  return (
    <header id="header">
      <div>
        <Slideshow {...props}/>
      </div>
      
      {/* <div className="intro">
        <div className="overlay">
          <div className="container"> 
            <div className="row"> 
               <div className="col-md-8 col-md-offset-2 intro-text">
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{" "} 
               </div> 
             </div> 
          </div>
        </div>
      </div> */}
    </header>
  );
};
