// src/components/Team.js
import React, { useState, useEffect } from "react";
import { storage } from '../config/firebaseConfig';
import { ref, getDownloadURL } from 'firebase/storage';

export const Team = (props) => {
  // State to store the image URLs for team members
  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    if (props.data) {
      // Function to fetch image URLs
      const fetchImageUrls = async () => {
        const urls = {};

        // Fetch the image URL for each team member
        await Promise.all(
          props.data.map(async (d) => {
            if (d.name) {
              // Construct the file name based on the person's name
              const extension = 'jpg'; // Adjust if your images have different extensions
              const fileName = `${d.name.replace(/\s+/g, '_').toLowerCase()}.${extension}`;
              const imageRef = ref(storage, `teams/${fileName}`);

              try {
                const url = await getDownloadURL(imageRef);
                urls[d.name] = url;
                //console.log(url);
              } catch (error) {
                //console.error(`Error fetching image for ${d.name}:`, error);
                // Optionally set a default image URL
                urls[d.name] = 'img/team/av.jpg'; // Replace with your default image path
              }
            }
          })
        );

        // Update the state with the fetched image URLs
        setImageUrls(urls);
      };

      fetchImageUrls();
    }
  }, [props.data]);

  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>
            The AOA (Apartment Owners Association) is a dedicated group
            committed to managing and maintaining the apartment complex,
            ensuring a harmonious and comfortable living environment for all
            residents.
          </p>
          <p>
            <br /> Our team works diligently to address the needs and concerns
            of the community, fostering a sense of unity and cooperation among
            apartment owners.
          </p>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) =>
                d.name ? (
                  <div
                    key={`${d.name}-${i}`}
                    className="col-md-3 col-sm-6 team"
                  >
                    <div className="thumbnail">
                      <div className="team-img-container">
                        <img
                          src={imageUrls[d.name] || 'img/team/av.jpg'}
                          alt={d.name}
                          className="team-img"
                        />
                      </div>
                      <div className="caption">
                        <h4>{d.name}</h4>
                        <p>{d.job}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={`empty-${i}`}
                    className="col-md-3 col-sm-6 team"
                  ></div>
                )
              )
            : "loading"}
        </div>
      </div>
    </div>
  );
};
