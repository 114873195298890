import React, { useState, useEffect } from "react";
import Footer from "./footer";

export const NoticeBoard = (props) => {
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    props.data && setNotices(props.data);
  }, [props]);

  return (
    <>
      <div className="notice">
        <header className="notice-intro">
          <div className="intro-text">
            <h1>AOA Notice Board</h1>
          </div>
        </header>

        {/* Notice container to hold all the notices */}
        <div id="notices" className="notice-container">
          {notices.length > 0 ? (
            notices.map((notice, index) => (
              <div className="notice-item-v2" key={index}>
                <h3 dangerouslySetInnerHTML={{ __html: notice.title }} />

                {/* Check if the content contains events */}
                {Array.isArray(notice.content?.events) ? (
                  <ul>
                    {notice.content.events.map((event, eventIndex) => (
                      <li key={eventIndex}>
                        <p>
                          <strong>{event.eventName}</strong>
                          {/* Nested activities list */}
                          {event.activities ? (
                            <ul>
                              {event.activities.map((activity, activityIndex) => (
                                <li key={activityIndex}>{activity}</li>
                              ))}
                            </ul>
                          ) : (
                            <br />
                          )}
                          <strong>Time: {event.time}</strong>
                          <br />
                        </p>
                      </li>
                    ))}
                  </ul>
                ) : notice.content?.link?.url ? (
                  <a
                    href={notice.content.link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>{notice.content.link.text}</strong>
                  </a>
                ) : (
                  <p>{notice.content}</p>
                )}
              </div>
            ))
          ) : (
            <p>No notices available at the moment.</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
