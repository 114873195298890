// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
 // Import Firebase Storage

const firebaseConfig = {
    apiKey: "AIzaSyDYHi5qmrecnGZN0DCBDKT845kGKPe6yAY",
    authDomain: "rustlecourt-3d109.firebaseapp.com",
    projectId: "rustlecourt-3d109",
    storageBucket: "rustlecourt-3d109.appspot.com",
    messagingSenderId: "1006417705502",
    appId: "1:1006417705502:web:92b52160e52005acaacc0f",
    measurementId: "G-WBHQZPHQS3"
  };


const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };