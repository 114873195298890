import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import QRCode from 'qrcode.react';
import './WebcamCapture.css';

const WebcamCapture = () => {
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [value3, setValue3] = useState('');

  const handleChangeValue1 = (event) => setValue1(event.target.value);
  const handleChangeValue2 = (event) => setValue2(event.target.value);
  const handleChangeValue3 = (event) => setValue3(event.target.value);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
  };

  const qrData = JSON.stringify({ value1, value2, value3, image: capturedImage });

  return (
    <div className="webcam-container">
      <h2>Generate QR Code with Captured Image</h2>
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter value 1"
          value={value1}
          onChange={handleChangeValue1}
        />
        <input
          type="text"
          placeholder="Enter value 2"
          value={value2}
          onChange={handleChangeValue2}
        />
        <input
          type="text"
          placeholder="Enter value 3"
          value={value3}
          onChange={handleChangeValue3}
        />
      </div>
      <div className="webcam-wrapper">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width="100%"
          height="100%"
        />
        <button onClick={capture}>Capture Photo</button>
      </div>
      {capturedImage && (
        <div className="captured-image-container">
          <h3>Captured Image:</h3>
          <img src={capturedImage} alt="Captured" />
        </div>
      )}
      <div className="qr-code">
        <QRCode
          value={qrData}
          size={256}
          renderAs="canvas"
          imageSettings={{
            src: 'img/team/04.jpg', // Replace with your own image URL
            x: null, // default center
            y: null, // default center
            height: 48,
            width: 48,
            excavate: true, // make the background transparent behind the logo
          }}
        />
      </div>
    </div>
  );
};

export default WebcamCapture;
