// src/components/Footer.js
import React from 'react';

const Footer = () => {
    return (
        <div id="footer">
            <div className="container text-center">
                <p>
                    &copy; 2024 Rustle Court Apartment Owners Association. All rights reserved.{" "}              
                </p>
            </div>
      </div>
    );
};

export default Footer;
